<template>
  <div class="index-body">
    <div class="banner"></div>
    <div class="brief-introduction">
      <div class="title">关于我们</div>
      <!-- <ul>
            <li>地&nbsp; 址：北京市海淀区中关村大街31号</li>
            <li>邮 编：100080</li>
            <li>社办公室：010-62510566</li>
            <li>投稿邮箱：crupzbs@crup.co</li>
            <li>质量反馈：crupzj@crup.com</li>
            <li>教师服务：3007900927@qq.c</li>
            <li>发行销售：010-62515195</li>
            <li>实体书店：010-62514148（海淀黄庄读者服务部）010-62514148（人民大学明德书店）</li>
            <li>网上书店：010-82501766</li>
            <li>盗版举报：010-62105275</li>
        </ul> -->

      <div class="content">
        <ul>
          <li
            style="cursor: pointer"
            v-for="item in contactusdataList"
            :class="item.id == acitve ? 'active' : ''"
            :key="item.id"
            @click="goContactus(item)"
          >
            {{ item.title }}
          </li>
        </ul>
             <div class="ql-container ql-snow">
                  <div class="ql-editor">
                      <div v-html="msg.content && decode(msg.content)"></div>
                  </div>
                </div>
      
      </div>
    </div>
  </div>
</template>

 
<script>
import Swiper from "swiper";
export default {
  name: "vue-index",

  data() {
    return {
      msg: {},
      contactusdataList: [],
      list: [],
      total: 0,
      acitve: 0,
    };
  },
  created() {
    this.contactusquery();
  },

  mounted() {
    new Swiper(".swiper-container", {
      loop: true,
      // // 如果需要分页器
      // pagination: '.swiper-pagination',
      // // 如果需要前进后退按钮
      // nextButton: '.swiper-button-next',
      // prevButton: '.swiper-button-prev',
      // // 如果需要滚动条
      // scrollbar: '.swiper-scrollbar',
    });
  },
  methods: {
    goDetail() {
      // log("contactus")
      // this.$router.push({ path: "/aboutusdetail" });
    },

    decode(base64) {
      // // 对base64转编码
      // var decode = atob(base64);
      // // 编码转字符串
      // var str = decodeURI(decode);
      return base64;
    },
    goContactus(e) {
     
      var is_mobi = navigator.userAgent.toLowerCase().match(/(ipod|ipad|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i) != null;
if (is_mobi) {
    this.$router.push({ path: "/aboutusdetail" ,query:{id:e.id}})
}else{
    this.msg = e;
    this.acitve = e.id;
}


    },
    
    contactusquery() {
      // let params 
      // if (self.frameElement && self.frameElement.tagName == "IFRAME") { 
      //     params = {}
      //   }else {
      //      params={
      //       status: 1,
      //     }
      //    } 
      this.$http
        .get("/aboutus/homePage", {
          // params
        })
        .then(({ data: res }) => {
          if (res.code !== 0) {
            this.dataList = [];
            this.total = 0;
            return this.$message.error(res.msg);
          }
          this.contactusdataList = res.data.list.filter(e=>e.saveStatus === 1 || e.id == this.$route.query.id );
          this.contactusdataList.forEach((e) => {
            if (e.id == this.$route.query.id) {
              this.acitve = this.$route.query.id;
              this.msg = e;
            }
          });
        })
        .catch(() => {});
    },
  },

  //监听参数的变化，如监听路由中id值的变化：
  watch: {
    "$route.query.id": {
      handler(newVal) {
        //判断newVal有没有值监听路由变化
        this.list = this.contactusdataList.filter((e) => e.id == newVal);
        this.acitve = newVal
        this.contactusdataList.forEach((e) => {
          if (e.id == newVal) {
            this.msg = e;
          }
        });
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.banner {
  margin-top: 0.56rem;
  height: 3.3rem;
  background: linear-gradient(180deg, #b20103 0%, #f8f8f8 100%);

  span {
    position: relative;
    left: 0.84rem;
    top: 0.64rem;
    font-size: 0.48rem;
    font-family: SourceHanSerifCN-Bold, SourceHanSerifCN;
    font-weight: bold;
    color: #ffffff;
    line-height: 0.56rem;
  }
}

.brief-introduction {
  position: relative;
  top: -2.5rem;
  box-sizing: border-box;
  margin: 0 0.64rem;
  width: 13.1rem;

  min-height: 4.57rem;
  background: #ffffff;
  border-radius: 0.02rem 0.02rem 0rem 0.02rem;
  margin-bottom: -0.88rem;

  padding: 0.32rem 0.4rem;
  .title {
    font-size: 0.2rem;

    font-weight: 500;
    color: rgba(0, 0, 0, 0.9);
    line-height: 0.28rem;
    padding-bottom: 0.16rem;
    border-bottom: 0.01rem solid #eeeeee;
    margin-bottom: 0.24rem;
  }

  .content {
    display: flex;
    ul {
      width: 1.96rem;
      margin-right: 0.6rem;
      li {
        width: 1.96rem;
        height: 0.48rem;

        color: rgba(0, 0, 0, 0.85);
        margin-bottom: 0.1rem;
        text-align: center;
        line-height: 0.48rem;
      }
      li:hover {
        background: #b20103;
        color: #ffffff;
      }
      .active {
        background: #b20103;
        color: #ffffff;
      }
    }
    font-size: 0.14rem;

    font-weight: 400;
    color: rgba(0, 0, 0, 0.9);
    line-height: 0.3rem;
    margin-bottom: 0.08rem;
  }
}
@media (max-width: 768px) {
  .banner {
    display: none;
  }
  .index-body {
    margin-top: 0;
  }
  .brief-introduction {
    padding: 0;
    top: 0;
    margin: 0;
    width: 100vw;
    .title {
      display: none;
    }

    .content {
      width: 100vw;
      div {
        display: none;
      }
      ul {
        margin: 0 0.15rem;
        box-sizing: border-box;

        .active {
          background: #ffffff;
          color: rgba(0, 0, 0, 0.9);
        }
        li {
          width: 3.45rem;
          height: 0.54rem;
          line-height: 0.54rem;
          background: #ffffff;
          font-size: 0.14rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.9);

          text-align: left;
          align-items: center;
          border-bottom: 1px solid #eeeeee;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &::after {
            display: block;
            content: "";
            width: 0.06rem;
            height: 0.11rem;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAWCAYAAAD0OH0aAAAAAXNSR0IArs4c6QAAAT9JREFUOE9jXNUXysnyhWMVI8PfhsC6ZWcZCADGdY3RGxgYGP0ZGBjeMDAx2gXVLr6OTw/j2sbocEYGxmUMDAxMDAwMTxn/s9gENix4gEsTI0hiXWN0KgMD4yyoorv/WRhsg6uXPMemCawBrKkppoThP0M3lHvlz/+f9mENq9+ha4JrgNgU28LA8L8aoojx1J//P5zDGlZ/QdaEogEksbYhZjIjI0MOVNP+j/+ZvRIbFvyAacLQ8P8/A+OGpuiF/xkYY0GK/jMwbBb6/yTIseHAH7C92Dy2alUoM/MN9jWM/xkCwIr+My67yKAc29DQ8A+rBpCibZNy2X9+eLfl/39GF7Ch///PCGpYmolTA0jNzu5Y7q/f/u9hYGCwgGhi7KCeBpKcRJKnSQ5WkiKOpKRBUuIjKXmTnIFIzqKkFgIAuqTAO9Kr6bMAAAAASUVORK5CYII=);
            background-size: 100% 100%;
          }
          &:hover{
            background: #ffffff;
              color: rgba(0, 0, 0, 0.9);
          }
        }
      }
    }
  }
}
</style>